/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { Component, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
  Checkbox,
} from "antd";
import signinbg from "../assets/images/img-signin.jpg";
import {
  DribbbleOutlined,
  TwitterOutlined,
  InstagramOutlined,
  GithubOutlined,
} from "@ant-design/icons";
import VN from "../vn";
import Request from "../api/Request";
import constants from "../constants";
import { useNavigate } from "react-router";
import Welcome from "../components/Welcome";
import GoogleLogin from 'react-google-login';
const { Title } = Typography;
const { Header, Footer, Content } = Layout;


export default function SignIn() {

  const navigate = useNavigate()
  const submitHandler = async function (formData) {
    try {
      const response = await Request.xhr(
        `${constants.API_DOMAIN}/users/login`,
        false,
        { ...formData },
        "POST",
      )
      if (response.status === true || response.status === 200) {
        window.localStorage.setItem("token", response.token)
        window.localStorage.setItem('username', response.username)
        window.localStorage.setItem('role', JSON.stringify(response.role))
        window.location.reload(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const responseGoogle = async function (googleResponse) {
    try {
      const response = await Request.xhr(
        `${constants.API_DOMAIN}/users/login`,
        false,
        {
          googleToken: googleResponse.tokenId
        },
        "POST",
      )

      if (response.status === true || response.status === 200) {
        window.localStorage.setItem("token", response.token)
        window.localStorage.setItem('username', response.username)
        window.localStorage.setItem('role', JSON.stringify(response.role))
        window.location.reload(true)
      }

    } catch (error) {
      console.log(error)
    }
  }



  return (
    <>
      <Layout className="layout-default layout-signin">
        <Header>
          <div className="header-col header-brand"
            onClick={() => navigate('/')}
          >
            <h5>{VN.BANNER}</h5>
          </div>
        </Header>
        <Layout.Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">{VN.SIGN_IN}</Title>
              <Title className="font-regular text-muted" level={5}>
                {VN.ENTER_USERNAME_AND_PASSWORD}
              </Title>
              <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={submitHandler}
                autoComplete="off"
              >
                <Form.Item
                  label={VN.USERNAME}
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={VN.PASSWORD}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Checkbox>{VN.REMEMBER_PASSWORD}</Checkbox>
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    {VN.CONFIRM}
                  </Button>


                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <GoogleLogin
                    clientId={constants.GOOGLE_OAUTH_CLIENT_KEY}
                    buttonText="Đăng nhập với Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                  // cookiePolicy={'single_host_origin'}
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col
            >
              <Welcome />
            </Col>

          </Row>
        </Layout.Content>
      </Layout>
    </>
  );
}
