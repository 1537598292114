import axios from 'axios';
import {
    Form,
    Radio,
    Button,
    Upload,
    Input,
    Alert,
    Image,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import VN from '../../vn'
import Request from '../../api/Request'
import constants from '../../constants';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Teacher from '../../models/Teacher';
import LoadingSpin from '../../components/loadingSpin';
import utils from '../../utils';
const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};

export default function EditTeacher() {
    const endPoint = `${constants.API_DOMAIN}/teachers`
    const [image, setImage] = useState([])
    const [showAlert, setShowAlert] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const { state } = useLocation()
    const [data, setData] = useState(new Teacher())
    const { id, isEditing } = state || { id: null, isEditing: false }
    const fetchDetailData = async function () {
        if (!id || !isEditing) {
            return
        }
        setIsFetching(true)
        try {
            const response = await Request.xhr(
                `${endPoint}/get`,
                false,
                { teacherId: id, },
                "POST"
            )
            const data = new Teacher(response.data)
            setData(data)
        } catch (error) {
            console.log(error)
        }
        setIsFetching(false)
    }

    const handleUpload = ({ fileList }) => {
        setImage(fileList)
    };
    const submitData = async function (formData) {
        setIsFetching(true)
        try {
            const action = `${endPoint}/${isEditing ? 'update' : 'create'}`
            let reqData = {
                gender: formData.gender,
                name: formData.name,
                description: formData.description,
                teacherAvatar: image.length > 0 ? image[0].originFileObj : null,
            }
            if (isEditing) {
                reqData.teacherId = data.id
            }
            const resData = await Request.xhr(
                action,
                false,
                reqData,
                'POST',
                true
            )
            if (resData.status === 200) {
                setShowAlert(true)
                await utils.getGlobalData()
                setTimeout(() => {
                    setShowAlert(false)
                    window.history.go(-1)
                }, constants.ALERT_TIME_OUT);
            } else {
                alert(VN.FIELD_ERROR)
            }
        } catch (error) {
            console.log(error)
        }
        setIsFetching(false)
    }
    const onFinish = (values) => {
        submitData(values)
    };

    useEffect(() => {
        fetchDetailData()
    }, [])

    if (isFetching) {
        return <LoadingSpin />
    }

    return (
        <Form
            name="validate_other"
            {...formItemLayout}
            onFinish={onFinish}
        >
            {showAlert && <Alert
                message={VN.SUCCESS}
                type="success"
            />}
            <Form.Item
                initialValue={data.name}
                label={VN.NAME}
                name="name"
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Input placeholder={data.name} />
            </Form.Item>

            <Form.Item
                initialValue={data.description}
                label={VN.DESCRIPTION}
                name="description"
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Input.TextArea placeholder={data.description} />
            </Form.Item>

            <Form.Item initialValue={data.gender} name="gender" label={VN.GENDER}>
                <Radio.Group>
                    <Radio value={1}>{VN.MALE}</Radio>
                    <Radio value={2}>{VN.FEMALE}</Radio>
                    <Radio value={0}>{VN.OTHER}</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label={VN.IMAGE}
                required
            >
                {data.image && <Image src={data.image} height={constants.IMAGE_DEFAULT_HEIGHT} width={constants.IMAGE_DEFAULT_WIDTH} />}
                <Upload
                    multiple={false}
                    fileList={image}
                    onChange={handleUpload}
                    beforeUpload={() => false}
                    listType="picture-card">
                    <Button icon={<UploadOutlined />}>{VN.UPLOAD_IMAGE}</Button>
                </Upload>
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    span: 12,
                    offset: 6,
                }}
            >
                <Button
                    disabled={isFetching}
                    type="primary" htmlType="submit">
                    {VN.CONFIRM}
                </Button>
            </Form.Item>
        </Form>
    );
};
