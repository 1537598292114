import axios from 'axios';
import {
    Form,
    Radio,
    Button,
    Upload,
    Input,
    Select,
    Alert,
    DatePicker,
    Row, Image, Col,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import VN from '../../vn'
import Request from '../../api/Request'
import constants from '../../constants';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Livestream from '../../models/Livestream';
import Channel from '../../models/Channel';
import utils from '../../utils';
import Class from '../../models/Class';
import Subject from '../../models/Subject';
import Teacher from '../../models/Teacher';
import SourceLive from '../../models/SourceLive'
import dateFormat from 'dateformat';
import ReactSelect from 'react-select';
import LoadingSpin from '../../components/loadingSpin';
import { MultiSelect } from "react-multi-select-component";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-custom';
const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};

export default function EditLivestream() {
    const endPoint = `${constants.API_DOMAIN}/live`
    const [showAlert, setShowAlert] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const { state } = useLocation()
    const [data, setData] = useState(new Livestream())
    const { id, isEditing } = state || { id: null, isEditing: false }
    const [channel, setChannel] = useState([])
    const [classes, setClasses] = useState([])
    const [subject, setSubject] = useState([])
    const [teacher, setTeacher] = useState([])
    const [sourceLive, setSourceLive] = useState([])

    const [smallCover, setSmallCover] = useState([])
    const [mediumCover, setMediumCover] = useState([])
    const [largeCover, setLargeCover] = useState([])

    const [startDate, setStartDate] = useState(new Date())
    const [finishDate, setFinishDate] = useState(new Date())

    const [isLivestream, setIsLivestream] = useState(false)

    const [selectedPkgname, setSelectedPkgname] = useState([])
    const [selectedChannel, setSelectedChannel] = useState([])
    const [selectedTeacher, setSelectedTeacher] = useState([])
    const [selectedClass, setSelectedClass] = useState([])
    const [selectedSubject, setSelectedSubject] = useState([])
    const [description, setDescription] = useState('')

    const fetchDetailData = async function () {
        setIsFetching(true)
        try {
            const response = await Request.xhr(
                `${endPoint}/get`,
                false,
                { liveId: id, },
                "POST"
            )
            const data = new Livestream(response.data)
            setStartDate(data.startTime)
            setFinishDate(data.endTime)
            setIsLivestream(data.method === constants.LIVESTREAM_METHOD.NEW_LIVE)
            setData(data)
            setDescription(data.description)

            setSelectedPkgname((data.pkgname).map((item) => ({ label: utils.mapPkgname(item), value: item })))
            setSelectedChannel((data.channelId).map((item) => ({ label: utils.mapChannel(item), value: item })))
            setSelectedTeacher((data.teacherId).map((item) => ({ label: utils.mapTeacher(item), value: item })))
            setSelectedClass((data.classId).map((item) => ({ label: utils.mapClass(item), value: item })))
            setSelectedSubject((data.subjectId).map((item) => ({ label: utils.mapSubject(item), value: item })))
        } catch (error) {
            console.log(error)
        }
        setIsFetching(false)
    }
    const fetchChannel = async function () {
        try {
            const resData = await Request.xhr(
                constants.API_DOMAIN + '/api/getChannel'
            )
            const data = utils.mapDataModel(resData.data, Channel)
            setChannel(data)
        } catch (error) {
            console.log(error)
        }
    }

    const fetchTeacher = async function () {
        try {
            const resData = await Request.xhr(
                constants.API_DOMAIN + '/teachers'
            )
            const data = utils.mapDataModel(resData.data, Teacher)
            setTeacher(data)
        } catch (error) {
            console.log(error)
        }
    }

    const fetchClass = async function () {
        try {
            const resData = await Request.xhr(
                constants.API_DOMAIN + '/class'
            )
            const data = utils.mapDataModel(resData.data, Class)
            setClasses(data)
        } catch (error) {
            console.log(error)
        }
    }

    const fetchSubject = async function () {
        try {
            const resData = await Request.xhr(
                constants.API_DOMAIN + '/subject'
            )
            const data = utils.mapDataModel(resData.data, Subject)
            setSubject(data)
        } catch (error) {
            console.log(error)
        }
    }

    const fetchSourceLive = async function () {
        try {
            const resData = await Request.xhr(
                constants.API_DOMAIN + '/sourceLive',
                false,
                {
                    channelId: null,
                    classId: null,
                    subjectId: null,
                    name: null
                },
                'POST',
            )
            const data = utils.mapDataModel(resData.data, SourceLive)
            for (let item of data) {
                item.value = item.id
                item.label = `ID: ${item.id} - ${item.name}`
            }
            setSourceLive(data)
        } catch (error) {
            console.log(error)
        }
    }

    const submitData = async function (formData) {
        // if (smallCover.length === 0 || mediumCover.length === 0 || largeCover.length === 0) {
        //     alert(VN.NOT_ENOUGH_IMAGE)
        //     return
        // }
        setIsFetching(true)
        try {
            const action = `${endPoint}/${isEditing ? 'update' : 'create'}`
            let reqData = {
                ...formData,
                sourceLiveId: isEditing ? formData.sourceLiveId : formData.sourceLiveId.id,
                startTime: startDate.toISOString(),
                endTime: finishDate.toISOString(),
                pkgname: JSON.stringify(selectedPkgname.map((item) => item.value)),
                description,
                // hình thức livestream: 1 - có sẵn | 2 - phát trực tiếp
                // tạm thời chưa có tính năng live, chỉ có video phát lại giả liveId => hardcode là 2
                method: 2,
                channelId: JSON.stringify(selectedChannel.map((item) => item.value)),
                teacherId: JSON.stringify(selectedTeacher.map((item) => item.value)),
                subjectId: JSON.stringify(selectedSubject.map((item) => item.value)),
                classId: JSON.stringify(selectedClass.map((item) => item.value)),
            }
            if (typeof formData.sourceLiveId == 'object') {
                reqData.sourceLiveId = formData.sourceLiveId.id
            }
            if (smallCover.length > 0) {
                reqData.smallCover = smallCover[0].originFileObj
            }

            if (mediumCover.length > 0) {
                reqData.mediumCover = mediumCover[0].originFileObj
            }

            if (largeCover.length > 0) {
                reqData.largeCover = largeCover[0].originFileObj
            }
            if (isEditing) {
                reqData.liveId = data.id
            }
            const resData = await Request.xhr(
                action,
                false,
                reqData,
                'POST',
                true
            )

            if (resData.status === 200) {
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                    window.history.go(-1)
                }, constants.ALERT_TIME_OUT);
            }
        } catch (error) {
            console.log(error)
        }
        setIsFetching(false)
    }
    const onFinish = (values) => {
        submitData(values)
    };

    function onChangeDate(value, dateString) {
        if (value) {
            const startDate = value[0]._d
            const endDate = value[1]._d
            setStartDate(startDate)
            setFinishDate(endDate)
        }

    }


    useEffect(() => {
        console.log({ id, isEditing })
        if (id && isEditing) {
            fetchDetailData()
        }
        fetchChannel()
        fetchClass()
        fetchSubject()
        fetchTeacher()
        fetchSourceLive()
    }, [])

    if (isFetching) {
        return <LoadingSpin />
    }
    console.log(data.sourceLiveRef)
    return (
        <Form
            name="validate_other"
            {...formItemLayout}
            onFinish={onFinish}
        >
            {showAlert && <Alert
                message={VN.SUCCESS}
                type="success"
            />}
            <Form.Item
                label={VN.SOURCE_LIVE}
                name="sourceLiveId"
                initialValue={data.sourceLiveRef.id}
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <ReactSelect
                    placeholder={data.sourceLiveRef.id === null && data.sourceLiveRef.name === "" ? "" : `ID: ${data.sourceLiveRef.id} - ${data.sourceLiveRef.name}`}
                    value={data.sourceLiveRef.id}
                    options={sourceLive}

                />
            </Form.Item>

            <Form.Item
                initialValue={data.name}
                label={VN.NAME}
                name="name"
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Input placeholder={data.name} />
            </Form.Item>

            <Form.Item
                label={VN.DESCRIPTION}
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data)
                    }}
                />
            </Form.Item>
            <Form.Item
                name="channelId"
                label={VN.CHANNEL}
                initialValue={selectedChannel}
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <MultiSelect
                    options={channel.map((item) => ({ label: item.name, value: item.id }))}
                    value={selectedChannel}
                    onChange={value => {
                        setSelectedChannel(value)
                    }}
                />

            </Form.Item>

            <Form.Item
                name="teacherId"
                label={VN.TEACHER}
                initialValue={selectedTeacher}
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <MultiSelect
                    options={teacher.map((item) => ({ label: item.name, value: item.id }))}
                    value={selectedTeacher}
                    onChange={value => setSelectedTeacher(value)}
                />
            </Form.Item>


            <Form.Item
                name="classId"
                label={VN.CLASS}
                initialValue={selectedClass}
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <MultiSelect
                    options={classes.map((item) => ({ label: item.name, value: item.id }))}
                    value={selectedClass}
                    onChange={value => setSelectedClass(value)}
                />
            </Form.Item>

            <Form.Item
                name="subjectId"
                label={VN.SUBJECT}
                initialValue={selectedSubject}
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <MultiSelect
                    options={subject.map((item) => ({ label: item.name, value: item.id }))}
                    value={selectedSubject}
                    onChange={value => setSelectedSubject(value)}
                />
            </Form.Item>


            <Form.Item initialValue={data.requireLogin} name="requireLogin" label={VN.REQUIRE_LOGIN}>
                <Radio.Group>
                    <Radio value={true}>{VN.YES}</Radio>
                    <Radio value={false}>{VN.NO}</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item initialValue={data.isTest} name="isTest" label={VN.TEST}>
                <Radio.Group>
                    <Radio value={true}>{VN.YES}</Radio>
                    <Radio value={false}>{VN.NO}</Radio>
                </Radio.Group>
            </Form.Item>


            <Form.Item
                name="appId"
                label={VN.PKGNAME}
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
                initialValue={selectedPkgname}
            >
                <MultiSelect
                    disableSearch
                    options={constants.PKGNAME_LIST.map((item) => ({ label: utils.mapPkgname(item), value: item }))}
                    value={selectedPkgname}
                    onChange={value => setSelectedPkgname(value)}
                />

            </Form.Item>

            <Form.Item initialValue={data.method} name="method" label={VN.METHOD}>
                <Radio.Group
                    onChange={(event) => setIsLivestream(event.target.value === constants.LIVESTREAM_METHOD.NEW_LIVE)}
                >
                    <Radio disabled={true} value={constants.LIVESTREAM_METHOD.NEW_LIVE}>{VN.LIVE_NOW}</Radio>
                    <Radio value={constants.LIVESTREAM_METHOD.RECORDED_VIDEO}>{VN.LIVE_LATER}</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                required
                label={VN.SHOW_TIME}
            >
                <DatePicker.RangePicker

                    disabled={[isLivestream, false]}
                    placeholder={[dateFormat(startDate, constants.DATE_FORMAT), dateFormat(finishDate, constants.DATE_FORMAT)]}
                    showTime={{ format: "HH:mm:ss - YYYY/MM/DD" }}
                    format="HH:mm:ss - YYYY/MM/DD"
                    onChange={onChangeDate}
                />
            </Form.Item>


            <Form.Item
                label={VN.SMALL_COVER}
                initialValue={smallCover}
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                {data.smallCover && <Image src={data.smallCover} height={constants.IMAGE_DEFAULT_HEIGHT} width={constants.IMAGE_DEFAULT_WIDTH} />}
                <Upload
                    maxCount={1}
                    multiple={false}
                    fileList={smallCover}
                    onChange={({ fileList }) => setSmallCover(fileList)}
                    beforeUpload={() => false}
                    listType="picture-card">
                    <Button icon={<UploadOutlined />}>{VN.UPLOAD_IMAGE}</Button>
                </Upload>
            </Form.Item>

            <Form.Item
                label={VN.MEDIUM_COVER}
            >
                {data.smallCover && <Image src={data.mediumCover} height={constants.IMAGE_DEFAULT_HEIGHT} width={constants.IMAGE_DEFAULT_WIDTH} />}
                <Upload
                    maxCount={1}
                    multiple={false}
                    fileList={mediumCover}
                    onChange={({ fileList }) => setMediumCover(fileList)}
                    beforeUpload={() => false}
                    listType="picture-card">
                    <Button icon={<UploadOutlined />}>{VN.UPLOAD_IMAGE}</Button>
                </Upload>
            </Form.Item>

            <Form.Item
                label={VN.LARGE_COVER}
            >
                {data.smallCover && <Image src={data.largeCover} height={constants.IMAGE_DEFAULT_HEIGHT} width={constants.IMAGE_DEFAULT_WIDTH} />}
                <Upload
                    maxCount={1}
                    multiple={false}
                    fileList={largeCover}
                    onChange={({ fileList }) => setLargeCover(fileList)}
                    beforeUpload={() => false}
                    listType="picture-card">
                    <Button icon={<UploadOutlined />}>{VN.UPLOAD_IMAGE}</Button>
                </Upload>
            </Form.Item>



            <Form.Item
                wrapperCol={{
                    span: 12,
                    offset: 6,
                }}
            >
                <Button
                    disabled={isFetching}
                    type="primary" htmlType="submit">
                    {VN.CONFIRM}
                </Button>
            </Form.Item>
        </Form>
    );
};
