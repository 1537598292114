
import _ from 'lodash'
import constants from './constants';
import VN from './vn';
import { getTeacherData, getClassData, getChannelData, getSubjectData } from './services/actions'
const channels = JSON.parse(window.localStorage.getItem('channel'))
const classes = JSON.parse(window.localStorage.getItem('class'))
const subjects = JSON.parse(window.localStorage.getItem('subject'))
const teachers = JSON.parse(window.localStorage.getItem('teacher'))
const utils = {
    getGlobalData: async function () {
        console.log("REFETCH GLOBAL DATAAAAAAA")
        window.localStorage.setItem('teacher', JSON.stringify(await getTeacherData()))
        window.localStorage.setItem('class', JSON.stringify(await getClassData()))
        window.localStorage.setItem('channel', JSON.stringify(await getChannelData()))
        window.localStorage.setItem('subject', JSON.stringify(await getSubjectData()))
    },
    mapChannel(channelId) {
        const channel = channels.find((item) => item.id == channelId)
        if (!channel) return null
        return channel.name
    },
    mapClass(classId) {
        const clas = classes.find((item) => item.id == classId)
        if (clas == null || clas == undefined) return null
        return clas.name
    },
    mapTeacher(teacherId) {
        const teacher = teachers.find((item) => item.id == teacherId)
        if (!teacher) return null
        return teacher.name
    },
    mapSubject(subjectId) {
        const subject = subjects.find((item) => item.id == subjectId)
        if (!subject) return null
        return subject.name
    },

    mapDataModel(listData = [], model) {
        let results = [];
        if (!_.isEmpty(listData)) {
            results = listData.map(dr => new model(dr))
        }
        return results;
    },
    secondsToHms(seconds = 0) {
        const dateObj = new Date(seconds * 1000);
        const h = dateObj.getUTCHours();
        const m = dateObj.getUTCMinutes();
        const s = dateObj.getSeconds();

        return h.toString().padStart(2, '0') + ':' +
            m.toString().padStart(2, '0') + ':' +
            s.toString().padStart(2, '0');
    },
    objectToFormData(obj = {}) {
        let formData = new FormData();

        for (let key in obj) {
            formData.append(key, obj[key]);
        }
        return formData;
    },
    sleep: (ms) => new Promise(resolve => setTimeout(resolve, ms)),

    parseHTML: function (html) {
        if (!html) return ""
        var tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        let text = tempDivElement.innerText || tempDivElement.textContent
        return text
    },

    cutLongString: function (text, minLength = constants.MAXIMUM_LINE_LENGTH) {
        if (!text) return text
        if (text.length <= minLength) return text
        return text = text.slice(0, minLength) + "..."
    },

    mapPkgname: function (pkgname) {
        switch (pkgname) {
            case constants.PKGNAME_LIST[0]:
                return VN.HOCMAI_PRIMARY_ANDROID
            case constants.PKGNAME_LIST[1]:
                return VN.HOCMAI_PRIMARY_IOS
            case constants.PKGNAME_LIST[2]:
                return VN.HOCMAI_ANDROID
            case constants.PKGNAME_LIST[3]:
                return VN.HOCMAI_IOS
            default:
                return null;
        }
    },

    mapNotificationTypeTarget: function (target) {
        switch (target) {
            case constants.NOTIFICATION_TARGET.ALL:
                return VN.ALL
            case constants.NOTIFICATION_TARGET.INDIVIDUAL:
                return VN.INDIVIDUAL
            case constants.NOTIFICATION_TARGET.GROUP:
                return VN.GROUP
            default:
                return VN.ERROR;
        }
    },

    mapNotificationSeenStatus: function (seen) {
        switch (seen) {
            case true:
                return VN.SEEN
            case false:
                return VN.NOT_SEEN
            default:
                return VN.INVISIBLE
        }
    },

    mapLivestreamStatus: function (status) {
        switch (status) {
            case constants.LIVESTREAM_STATUS.COMING:
                return VN.COMING
            case constants.LIVESTREAM_STATUS.SHOWING:
                return VN.SHOWING
            case constants.LIVESTREAM_STATUS.CLOSED:
                return VN.CLOSED
            case constants.LIVESTREAM_STATUS.INVISIBLE:
                return VN.INVISIBLE
            default:
                return VN.ERROR;
        }
    },

    mapGender: function (gender) {
        switch (gender) {
            case 1:
                return VN.MALE
            case 2:
                return VN.FEMALE
            case 0:
                return VN.OTHER
            default:
                return null;
        }
    }
}

export default utils