/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
    Button,
    Image,
    Table,
} from "antd";
import { useEffect, useState } from "react";
import SourceLiveModel from "../../models/SourceLive";
import React from "react";
import constants from "../../constants";
import utils from "../../utils";
import Request from "../../api/Request";
import VN from "../../vn";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import ActionButtons from "../../components/actionButtons";
import LoadingSpin from "../../components/loadingSpin";

const columns = [
    {
        title: VN.ID,
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: VN.NAME,
        dataIndex: 'name',
        key: 'name',
    },

    {
        title: VN.LENGTH,
        dataIndex: 'length',
        key: 'length',
    },
    {
        title: VN.CREATED_AT,
        dataIndex: 'createdAt',
        key: 'createdAt',
    },
    {
        title: VN.CHANNEL,
        dataIndex: 'channelRef',
        key: 'channelRef',
        render: (channelRef) => channelRef.name
    },
    {
        title: VN.CLASS,
        dataIndex: 'classRef',
        key: 'classRef',
        render: (classRef) => classRef.name
    },
    {
        title: VN.SUBJECT,
        dataIndex: 'subjectRef',
        key: 'subjectRef',
        render: (subjectRef) => subjectRef.name
    },

    {
        title: VN.LINK,
        dataIndex: 'url',
        key: 'url',
        render: (url) => <a href={url}>{utils.cutLongString(url)}</a>
    },
    {
        title: VN.ACTION,
        key: "actions",
        dataIndex: 'actions',
    },
];

export default function SourceLive() {
    const [sourceLive, setSourceLive] = useState([])
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const deleteHandler = async function (id) {
        try {
            const response = await Request.xhr(
                `${constants.API_DOMAIN}/sourceLive/delete`,
                false,
                { sourceLiveId: id },
                "POST"

            )
            if (response.status === 200) {
                await fetchData()
            }

        } catch (error) {
            console.log(error)
        }
    }
    const fetchData = async function () {
        setLoading(true)
        try {
            const response = await Request.xhr(`${constants.API_DOMAIN}/sourceLive`, false, {}, 'POST')
            let data = utils.mapDataModel(response.data, SourceLiveModel)
            for (let item of data) {
                item.actions = <ActionButtons
                    onEdit={() => navigate('edit', {
                        state: {
                            id: item.id,
                            isEditing: true,
                        }
                    })}
                    onDelete={() => deleteHandler(item.id)}
                />
            }
            setSourceLive(data)

        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [])

    if (loading) {
        return <LoadingSpin />
    }
    return (
        <>
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate('edit')}
            >{VN.CREATE}</Button>
            <Table
                dataSource={sourceLive}
                columns={columns} scroll={{ x: constants.TABLE_SCROLL_X, }} />
        </>
    );
}

