import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";
import Class from "./Class";

export default class Channel {
    constructor(data = {}) {
        this.name = data.name || ""
        this.description = data.description || ''
        this.id = data.channelId || null
        this.image = data.image || ''

        // those attribute is for filter
        this.text = this.name
        this.value = this.id

    }
}