/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
    Button,
    Col,
    Image,
    Table,
    Input,
    Row,
    Pagination,

} from "antd";
import { useEffect, useState } from "react";
import React from "react";
import constants from "../../constants";
import utils from "../../utils";
import Request from "../../api/Request";
import VN from "../../vn";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import ActionButtons from "../../components/actionButtons";
import LoadingSpin from "../../components/loadingSpin";
import NotificationModel from '../../models/NotificationType'
const { Search } = Input;
const columns = [
    {
        title: VN.ID,
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: VN.EVENT,
        dataIndex: 'event',
        key: 'event',
    },
    {
        title: VN.NAME,
        dataIndex: 'title',
        key: 'title',
        render: (title) => <p>{`${title}`}</p>

    },
    {
        title: VN.DESCRIPTION,
        dataIndex: 'description',
        key: 'description',
        render: (description) =>
            <p>{` ${utils.cutLongString(description)}`}</p>

    },
    {
        title: VN.SEEN,
        dataIndex: 'seen',
        key: 'seen',
        render: (seen) => utils.mapNotificationSeenStatus(seen)
    },
    {
        title: VN.CONTENT,
        dataIndex: 'content',
        key: 'content',
        render: (content) => utils.cutLongString(utils.parseHTML(content))
    },
    // tạm thời disable hành động
    // {
    //     title: VN.ACTION,
    //     key: "actions",
    //     dataIndex: 'actions',
    // },
];

export default function NotificationList() {
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState(1);
    const [pageSize, setpageSize] = useState(10);
    const [total, setTotal] = useState(10);
    const deleteHandler = async function (id) {

    }
    const fetchData = async function () {
        setLoading(true)
        try {
            const response = await Request.xhr(`${constants.API_DOMAIN}/notification`, true, {
                page: current,
                rows_per_page: pageSize,
            }, "POST")
            let data = utils.mapDataModel(response.data, NotificationModel)
            for (let item of data) {
                item.actions = <ActionButtons
                    enableDelete={false}
                    onEdit={() => navigate('edit', {
                        state: {
                            event: item.event,
                            pkgname: item.pkgname.shift(),
                            isEditing: true,
                        }
                    })}
                    onDelete={() => deleteHandler(item.id)}
                />
            }
            setData(data)
            setTotal(response.total)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [pageSize, current])

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrent(pagination.current);
        setpageSize(pagination.pageSize);
    };

    return (
        <>
            <Row>
                <Col flex="1 1 200px">
                    {/* <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => navigate('edit')}
                    >{VN.CREATE}</Button> */}
                </Col>
                <Col flex="0 1 300px">
                    <Search placeholder={VN.SEARCH_BY_ID} style={{ width: 400 }} />
                </Col>
            </Row>
            <Table
                loading={loading}
                pagination={{
                    current: current,
                    pageSize: pageSize,
                    total: total,
                    showSizeChanger: true,
                }}
                onChange={handleTableChange}
                dataSource={data}
                columns={columns} scroll={{ x: constants.TABLE_SCROLL_X, }} />
        </>
    );
}

