import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";
import Channel from "./Channel";
import Class from "./Class";
import SourceLive from "./SourceLive";
import Subject from "./Subject";
import Teacher from './Teacher'
export default class Livestream {
    constructor(data = {}) {
        this.name = data.name || ""
        this.description = data.description || ''

        this.sourceLiveRef = new SourceLive({ ...data.sourceLiveRef })
        // this.sourceLiveId = data.sourceLiveId || null

        // this.channelRef = new Channel({ ...data.channelRef })
        this.channelId = data.channelId || []

        // this.classRef = new Class(data.classRef)
        this.classId = data.classId || []

        // this.subjectRef = new Subject(data.subjectRef)
        this.subjectId = data.subjectId || []

        // this.teacherRef = new Teacher(data.teacherRef)
        this.teacherId = data.teacherId || []

        this.requireLogin = data.requireLogin || false
        // method: 1 - hen giờ phát | 2 - phát trực tiếp
        this.method = data.method || constants.LIVESTREAM_METHOD.RECORDED_VIDEO
        this.isTest = data.isTest || false
        this.pkgname = data.pkgname || []
        this.startTime = data.startTime ? new Date(data.startTime) : new Date()
        this.endTime = data.endTime ? new Date(data.endTime) : new Date()
        this.smallCover = data.smallCover || null
        this.mediumCover = data.mediumCover || null
        this.largeCover = data.largeCover || null
        this.status = data.status || constants.LIVESTREAM_STATUS.ERROR
        this.detail = data.detail || { totalView_live: 0, totalLike_live: 0 }

        this.id = data.liveId || null
        this.key = this.id
    }
}