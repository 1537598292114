import utils from "../utils"

export default class Teacher {
    constructor(data = {}) {
        this.name = data.name || ""
        this.description = data.description || ''
        this.id = data.teacherId || null
        this.image = data.image || ""
        this.gender = data.gender || 0
        this.key = this.id

        // those attribute is for filter
        this.text = this.name
        this.value = this.id
    }
}