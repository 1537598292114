import axios from 'axios';
import {
    Form,
    Radio,
    Button,
    Upload,
    Input,
    Select,
    Alert,
    Image,
} from 'antd';
import { UploadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import VN from '../../vn'
import Request from '../../api/Request'
import constants from '../../constants';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import SourceLive from '../../models/SourceLive';
import LoadingSpin from '../../components/loadingSpin';
import NotificationType from '../../models/NotificationType';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-custom';

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};

const formItemLayoutWithOutLabel = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};
export default function EditNotificationType() {
    const endPoint = `${constants.API_DOMAIN}/notificationType`
    const [showAlert, setShowAlert] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const { state } = useLocation()
    const [data, setData] = useState(new SourceLive())
    const { id, event, isEditing } = state || { id: null, event: null, isEditing: false }
    const [content, setContent] = useState('')
    const [image, setImage] = useState([])
    const fetchDetailData = async function () {
        if (!event || !isEditing) {
            return
        }
        setIsFetching(true)
        try {

            const response = await Request.xhr(
                `${endPoint}/get`,
                false,
                {
                    // event: event,
                    // pkgname: pkgname,
                    id
                },
                "POST"
            )
            const data = new NotificationType(response.data)
            setData(data)
            setContent(data.content)
        } catch (error) {
            console.log(error)
        }
        setIsFetching(false)
    }



    const submitData = async function (formData) {
        setIsFetching(true)
        try {
            const action = `${endPoint}/${isEditing ? 'update' : 'create'}`
            let reqData = {
                ...formData,
                content,
                title: JSON.stringify(formData.title),
                description: JSON.stringify(formData.description)
            }
            if (isEditing) {
                // reqData.event = data.event
                // reqData.pkgname = data.pkgname.shift()
                reqData.id = data.id
            }
            if (image.length > 0) {
                reqData.image = image[0].originFileObj
            }
            const resData = await Request.xhr(
                action,
                false,
                reqData,
                'POST',
                true,
            )

            if (resData.status === 200) {
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                    window.history.go(-1)
                }, constants.ALERT_TIME_OUT);
            } else {
                alert(VN.FIELD_ERROR)
            }
        } catch (error) {
            console.log(error)
        }
        setIsFetching(false)
    }
    const onFinish = (values) => {
        submitData(values)
    };

    useEffect(() => {
        fetchDetailData()

    }, [])
    if (isFetching) {
        return <LoadingSpin />
    }

    return (
        <Form
            name="validate_other"
            {...formItemLayout}
            onFinish={onFinish}
        >
            {showAlert && <Alert
                message={VN.SUCCESS}
                type="success"
            />}
            <Form.Item
                initialValue={data.event}
                label={VN.EVENT}
            >
                <Input disabled={isEditing} placeholder={event} />
            </Form.Item>

            <Form.Item initialValue={data.target || constants.NOTIFICATION_TARGET.ALL} name="target" label={VN.TARGET}>
                <Radio.Group disabled={isEditing}>
                    <Radio value={constants.NOTIFICATION_TARGET.ALL}>{VN.ALL}</Radio>
                    <Radio value={constants.NOTIFICATION_TARGET.INDIVIDUAL}>{VN.INDIVIDUAL}</Radio>
                    <Radio value={constants.NOTIFICATION_TARGET.GROUP}>{VN.GROUP}</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                name="pushPopupTitle"
                initialValue={data.pushPopupTitle}
                label={VN.PUSH_POPUP_TITLE}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="pushPopupContent"
                initialValue={data.pushPopupContent}
                label={VN.PUSH_POPUP_CONTENT}
            >
                <Input />
            </Form.Item>

            <Form.List
                name="title"
                initialValue={data.title}
                rules={[
                    {
                        validator: async (_, values) => {
                            // if (!values || values.length < 2) {
                            //     return Promise.reject(new Error('At least 2 passengers'));
                            // }
                        },
                    },
                ]}
            >
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                label={index === 0 ? VN.TITLE + " - " + (index + 1) : index + 1}
                                required={true}
                                key={field.key}
                            >
                                <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: VN.PLEASE_ENTER_VALUE,
                                        },
                                    ]}
                                    noStyle
                                >
                                    <Input placeholder={VN.TITLE} style={{ width: '95%' }} />
                                </Form.Item>
                                {fields.length > 1 ? (
                                    <MinusCircleOutlined
                                        style={{ marginLeft: 15 }}
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                    />
                                ) : null}
                            </Form.Item>
                        ))}
                        <Form.Item
                            label={VN.ADD + " " + VN.TITLE}
                        >
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: '100%' }}
                                icon={<PlusOutlined />}
                            >
                                {VN.ADD}
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Form.List
                name="description"
                initialValue={data.description}
                rules={[
                    {
                        validator: async (_, values) => {
                            // if (!values || values.length < 2) {
                            //     return Promise.reject(new Error('At least 2 passengers'));
                            // }
                        },
                    },
                ]}
            >
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                label={index === 0 ? VN.DESCRIPTION + " - " + (index + 1) : index + 1}
                                required={true}
                                key={field.key}
                            >
                                <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: VN.PLEASE_ENTER_VALUE,
                                        },
                                    ]}
                                    noStyle
                                >
                                    <Input placeholder={VN.DESCRIPTION} style={{ width: '95%' }} />
                                </Form.Item>
                                {fields.length > 1 ? (
                                    <MinusCircleOutlined
                                        style={{ marginLeft: 15 }}
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                    />
                                ) : null}
                            </Form.Item>
                        ))}
                        <Form.Item
                            label={VN.ADD + " " + VN.DESCRIPTION}
                        >
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: '100%' }}
                                icon={<PlusOutlined />}
                            >
                                {VN.ADD}
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>


            <Form.Item
                label={VN.CONTENT}
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <CKEditor
                    editor={ClassicEditor}
                    data={content}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setContent(data)
                    }}
                />
            </Form.Item>

            <Form.Item
                label={VN.IMAGE}
                initialValue={image}
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                {data.image &&
                    <Image src={data.image}
                        height={constants.IMAGE_DEFAULT_HEIGHT}
                        width={constants.IMAGE_DEFAULT_WIDTH} />
                }
                <Upload
                    maxCount={1}
                    multiple={false}
                    fileList={image}
                    onChange={({ fileList }) => setImage(fileList)}
                    beforeUpload={() => false}
                    listType="picture-card">
                    <Button icon={<UploadOutlined />}>{VN.UPLOAD_IMAGE}</Button>
                </Upload>
            </Form.Item>


            <Form.Item
                wrapperCol={{
                    span: 12,
                    offset: 6,
                }}
            >
                <Button
                    disabled={isFetching}
                    type="primary" htmlType="submit">
                    {VN.CONFIRM}
                </Button>
            </Form.Item>
        </Form>
    );
};
