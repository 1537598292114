import axios from 'axios';
import {
    Form,
    Radio,
    Button,
    Upload,
    Input,
    Alert,
    Select,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import VN from '../../vn'
import Request from '../../api/Request'
import constants from '../../constants';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Class from '../../models/Class';
import utils from '../../utils';
import Channel from '../../models/Channel';
import LoadingSpin from '../../components/loadingSpin';
const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};

export default function EditClass() {
    const endPoint = `${constants.API_DOMAIN}/class`
    const [showAlert, setShowAlert] = useState(false)
    const [isFetching, setIsFetching] = useState(true)
    const { state } = useLocation()
    const [data, setData] = useState(new Class())
    const [parent, setParent] = useState([])
    const { id, isEditing } = state || { id: null, isEditing: false }
    const fetchDetailData = async function () {
        await fetchParent()
        if (!id || !isEditing) {
            return
        }
        setIsFetching(true)
        try {
            const response = await Request.xhr(
                `${endPoint}/get`,
                false,
                { classId: id, },
                "POST"
            )
            const data = new Class(response.data)
            setData(data)
        } catch (error) {
            console.log(error)
        }
        setIsFetching(false)
    }

    const fetchParent = async function () {
        setIsFetching(true)
        try {
            const resData = await Request.xhr(
                constants.API_DOMAIN + '/api/getChannel'
            )
            const data = utils.mapDataModel(resData.data, Channel)
            setParent(data)
        } catch (error) {
            console.log(error)
        }
        setIsFetching(false)
    }

    const submitData = async function (formData) {
        setIsFetching(true)
        try {
            const action = `${endPoint}/${isEditing ? 'update' : 'create'}`
            let reqData = {
                name: formData.name,
                description: formData.description,
                channelId: formData.channelId
            }
            if (isEditing) {
                reqData.classId = data.id
            }
            const resData = await Request.xhr(
                action,
                false,
                reqData,
                'POST',
            )
            if (resData.status === 200) {
                setShowAlert(true)
                setTimeout(async () => {
                    await setShowAlert(false)
                    window.history.go(-1)
                }, constants.ALERT_TIME_OUT);

            } else {
                alert(VN.FIELD_ERROR)
            }
        } catch (error) {
            console.log(error)
        }
        setIsFetching(false)
    }
    const onFinish = (values) => {
        submitData(values)
    };

    useEffect(() => {
        fetchDetailData()
    }, [])

    if (isFetching) {
        return <LoadingSpin />
    }

    return (
        <Form
            name="validate_other"
            {...formItemLayout}
            onFinish={onFinish}
        >
            {showAlert && <Alert
                message={VN.SUCCESS}
                type="success"
            />}
            <Form.Item
                initialValue={data.name}
                label={VN.NAME}
                name="name"
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Input placeholder={data.name} />
            </Form.Item>

            <Form.Item
                initialValue={data.description}
                label={VN.DESCRIPTION}
                name="description"
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Input.TextArea placeholder={data.description} />
            </Form.Item>
            <Form.Item
                name="channelId"
                label={VN.CHANNEL}
                hasFeedback
                initialValue={data.channelId}
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Select placeholder={data.channelName}>
                    {parent.map((item) => <Select.Option value={item.id}>{item.name}</Select.Option>)}

                </Select>
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    span: 12,
                    offset: 6,
                }}
            >
                <Button
                    disabled={isFetching}
                    type="primary" htmlType="submit">
                    {VN.CONFIRM}
                </Button>
            </Form.Item>
        </Form>
    );
};
