import constants from "../constants"
import Channel from "../models/Channel"
import Class from "../models/Class"
import Livestream from "../models/Livestream"
import SourceLive from "../models/SourceLive"
import Subject from "../models/Subject"
import Teacher from "../models/Teacher"
import utils from "../utils"
import Request from "../api/Request"
export async function getLivestreamData() {
    try {
        const response = await Request.xhr(`${constants.API_DOMAIN}/live`)
        let data = utils.mapDataModel(response.data, Livestream)
        return data
    } catch (error) {
        throw error
    }
}

export async function getTeacherData() {
    try {
        const response = await Request.xhr(`${constants.API_DOMAIN}/teachers`)
        let data = utils.mapDataModel(response.data, Teacher)
        return data
    } catch (error) {
        throw error
    }
}

export async function getChannelData() {
    try {
        const response = await Request.xhr(`${constants.API_DOMAIN}/api/getChannel`)
        let data = utils.mapDataModel(response.data, Channel)
        return data
    } catch (error) {
        throw error
    }
}

export async function getSubjectData() {
    try {
        const response = await Request.xhr(`${constants.API_DOMAIN}/subject`)
        let data = utils.mapDataModel(response.data, Subject)
        return data
    } catch (error) {
        throw error
    }
}

export async function getClassData() {
    try {
        const response = await Request.xhr(`${constants.API_DOMAIN}/class`)
        let data = utils.mapDataModel(response.data, Class)
        return data
    } catch (error) {
        throw error
    }
}

export async function getSourceLiveData() {
    try {
        const response = await Request.xhr(`${constants.API_DOMAIN}/sourceLive`, false, {}, 'POST')
        let data = utils.mapDataModel(response.data, SourceLive)
        return data
    } catch (error) {
        throw error
    }
}
export function getLocalStorage(key) {
    return JSON.parse(window.localStorage.getItem(key))
}