import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";
import Channel from "./Channel";
export default class Class {
    constructor(data = {}) {
        this.name = data.name || ""
        this.channelRef = new Channel({ ...data.channelRef })
        this.id = data.classId || 0
        this.description = utils.parseHTML(data.description)
        this.channelId = data.channelId || null

        // those attribute is for filter
        this.text = this.name
        this.value = this.id

    }
}