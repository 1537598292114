/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
    Button,
    Col,
    Image,
    Row,
    Table,
    Input,
} from "antd";
import { useEffect, useState } from "react";
import React from "react";
import constants from "../../constants";
import utils from "../../utils";
import Request from "../../api/Request";
import VN from "../../vn";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import ActionButtons from "../../components/actionButtons";
import LoadingSpin from "../../components/loadingSpin";
import NotificationModel from '../../models/NotificationType'
const { Search } = Input;
const columns = [
    {
        title: VN.EVENT,
        dataIndex: 'event',
        key: 'event',
    },
    {
        title: VN.PKGNAME,
        dataIndex: 'pkgname',
        key: 'pkgname',
        render: (pkgname) => pkgname.map((item) => <Row>{utils.mapPkgname(item)}</Row>)
    },
    {
        title: VN.NAME,
        dataIndex: 'title',
        key: 'title',
        render: (title) => <Col>
            {title.map((item) => <p>{`- ${item}`}</p>)}
        </Col>
    },
    {
        title: VN.DESCRIPTION,
        dataIndex: 'description',
        key: 'description',
        render: (description) => <Col>
            {description.map((item) => <p>{`- ${utils.cutLongString(item)}`}</p>)}
        </Col>
    },
    // {
    //     title: VN.TARGET,
    //     dataIndex: 'target',
    //     key: 'target',
    //     render: (target) => utils.mapNotificationTypeTarget(target)
    // },
    {
        title: VN.SMALL_COVER,
        dataIndex: 'image',
        key: 'image',
        render: (image) => <Image src={image}
            width={constants.IMAGE_DEFAULT_WIDTH}
            height={constants.IMAGE_DEFAULT_HEIGHT}
        />

    },
    {
        title: VN.CONTENT,
        dataIndex: 'content',
        key: 'content',
        render: (content) => utils.cutLongString(utils.parseHTML(content))
    },
    {
        title: VN.ACTION,
        key: "actions",
        dataIndex: 'actions',
    },
];

export default function NotificationType() {
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const deleteHandler = async function (id) {

    }
    const fetchData = async function () {
        setLoading(true)
        try {
            const response = await Request.xhr(`${constants.API_DOMAIN}/notificationType`)
            let data = utils.mapDataModel(response.data, NotificationModel)
            console.log(data)
            for (let item of data) {
                item.actions = <ActionButtons
                    enableDelete={false}
                    onEdit={() => navigate('edit', {
                        state: {
                            id: item.id,
                            event: item.event,
                            isEditing: true,
                        }
                    })}
                    onDelete={() => deleteHandler(item.id)}
                />
            }
            setData(data)
            console.log({ data })
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [])



    if (loading) {
        return <LoadingSpin />
    }
    return (
        <>
            <Row>
                {/* <Col flex="1 1 200px">
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => navigate('edit')}
                    >{VN.CREATE}</Button>
                </Col> */}
            </Row>

            <Table
                dataSource={data}
                columns={columns} scroll={{ x: constants.TABLE_SCROLL_X, }} />
        </>
    );
}

