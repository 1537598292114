import axios from 'axios';
import {
    Form,
    Radio,
    Button,
    Upload,
    Input,
    Select,
    Alert,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import VN from '../../vn'
import Request from '../../api/Request'
import constants from '../../constants';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import SourceLive from '../../models/SourceLive';
import Channel from '../../models/Channel';
import utils from '../../utils';
import Class from '../../models/Class';
import Subject from '../../models/Subject';
import LoadingSpin from '../../components/loadingSpin';
const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};

export default function EditSourceLive() {
    const endPoint = `${constants.API_DOMAIN}/sourceLive`
    const [showAlert, setShowAlert] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const { state } = useLocation()
    const [data, setData] = useState(new SourceLive())
    const { id, isEditing } = state || { id: null, isEditing: false }
    const [channel, setChannel] = useState([])
    const [classes, setClasses] = useState([])
    const [subject, setSubject] = useState([])
    const fetchDetailData = async function () {
        await fetchChannel()
        await fetchClass()
        await fetchSubject()
        if (!id || !isEditing) {
            return
        }
        setIsFetching(true)
        try {

            const response = await Request.xhr(
                `${endPoint}/get`,
                false,
                { sourceLiveId: id, },
                "POST"
            )
            const data = new SourceLive(response.data)
            setData(data)
        } catch (error) {
            console.log(error)
        }
        setIsFetching(false)
    }

    const fetchChannel = async function () {
        try {
            const resData = await Request.xhr(
                constants.API_DOMAIN + '/api/getChannel'
            )
            const data = utils.mapDataModel(resData.data, Channel)
            setChannel(data)
        } catch (error) {
            console.log(error)
        }
    }

    const fetchClass = async function () {
        try {
            const resData = await Request.xhr(
                constants.API_DOMAIN + '/class'
            )
            const data = utils.mapDataModel(resData.data, Class)
            setClasses(data)
        } catch (error) {
            console.log(error)
        }
    }


    const fetchSubject = async function () {
        try {
            const resData = await Request.xhr(
                constants.API_DOMAIN + '/subject'
            )
            const data = utils.mapDataModel(resData.data, Subject)
            setSubject(data)
        } catch (error) {
            console.log(error)
        }
    }


    const submitData = async function (formData) {
        setIsFetching(true)
        try {
            const action = `${endPoint}/${isEditing ? 'update' : 'create'}`
            let reqData = {
                ...formData
            }
            if (isEditing) {
                reqData.sourceLiveId = data.id
            }
            const resData = await Request.xhr(
                action,
                false,
                reqData,
                'POST',
            )
            if (resData.status === 200) {
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                    window.history.go(-1)
                }, constants.ALERT_TIME_OUT);
            } else {
                alert(VN.FIELD_ERROR)
            }
        } catch (error) {
            console.log(error)
        }
        setIsFetching(false)
    }
    const onFinish = (values) => {
        submitData(values)
    };

    useEffect(() => {
        fetchDetailData()

    }, [])
    if (isFetching) {
        return <LoadingSpin />
    }

    return (
        <Form
            name="validate_other"
            {...formItemLayout}
            onFinish={onFinish}
        >
            {showAlert && <Alert
                message={VN.SUCCESS}
                type="success"
            />}
            <Form.Item
                initialValue={data.name}
                label={VN.NAME}
                name="name"
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Input placeholder={data.name} />
            </Form.Item>

            <Form.Item
                initialValue={data.url}
                label={VN.URL}
                name="url"
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Input.TextArea placeholder={data.url} />
            </Form.Item>

            <Form.Item
                name="channelId"
                label={VN.CHANNEL}
                hasFeedback
                initialValue={data.channelRef.id}
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Select placeholder={data.channelRef.name}>
                    {channel.map((item) => <Select.Option value={item.id}>{item.name}</Select.Option>)}

                </Select>
            </Form.Item>

            <Form.Item
                name="classId"
                label={VN.CLASS}
                hasFeedback
                initialValue={data.classRef.id}
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Select placeholder={data.classRef.name}>
                    {classes.map((item) => <Select.Option value={item.id}>{item.name}</Select.Option>)}

                </Select>
            </Form.Item>

            <Form.Item
                name="subjectId"
                label={VN.SUBJECT}
                hasFeedback
                initialValue={data.subjectRef.id}
                rules={[
                    {
                        required: true,
                        message: VN.PLEASE_ENTER_VALUE,
                    },
                ]}
            >
                <Select placeholder={data.subjectRef.name}>
                    {subject.map((item) => <Select.Option value={item.id}>{item.name}</Select.Option>)}

                </Select>
            </Form.Item>


            <Form.Item
                wrapperCol={{
                    span: 12,
                    offset: 6,
                }}
            >
                <Button
                    disabled={isFetching}
                    type="primary" htmlType="submit">
                    {VN.CONFIRM}
                </Button>
            </Form.Item>
        </Form>
    );
};
