
import {
    Button,
    Image,
    Table,
    Tag,
    Card, Col, Row,
    PageHeader,
    Statistic,
    Dropdown,
    InputNumber
} from "antd";
import dateformat from 'dateformat'
import { useContext, useEffect, useState } from "react";
import LivestreamModel from "../../models/Livestream";
import React from "react";
import constants from "../../constants";
import utils from "../../utils";
import Request from "../../api/Request";
import VN from "../../vn";
import LoadingSpin from "../../components/loadingSpin";
import { ArrowDownOutlined, ArrowUpOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import ActionButtons from "../../components/actionButtons";
import { Bar } from '@ant-design/plots';
import LivestreamStat from "../../models/LivestreamStat";
import _ from 'lodash'

// const data = [

// ];

const config = {
    isGroup: true,
    xField: 'value',
    yField: 'label',

    color: ['#4157fc', 'lightgreen'],
    seriesField: 'type',
    marginRatio: 0,
    label: {
        offsetX: 100,
        offsetY: 100,
        position: 'bottom',
        layout: [
            {
                type: 'interval-adjust-position',
            },
            {
                type: 'interval-hide-overlap',
            },
            {
                type: 'adjust-color',
            },
        ],
    },
};


export default function LivestreamStats() {
    const [data, setData] = useState([])
    const [dataView, setDataView] = useState(0)
    const [dataViewDiff, setDataViewDiff] = useState(0)
    const [dataLike, setDataLike] = useState(0)
    const [dataLikeDiff, setDataLikeDiff] = useState(0)
    const [dataComment, setDataComment] = useState(0)
    const [dataCommentDiff, setDataCommentDiff] = useState(0)
    const [numLiveStats, setNumLiveStats] = useState(100)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const fetchData = async function () {
        setLoading(true)
        try {
            const response = await Request.xhr(`${constants.API_DOMAIN}/live/stats?numLiveStats=${numLiveStats}`)
            let dataResp = utils.mapDataModel(response.data, LivestreamStat)
            dataResp = dataResp
                .filter((item) => item.live !== null)
                .map((item) => [
                    {
                        label: item.live.id + " - " + utils.cutLongString(item.live.name, 30),
                        type: VN.NUM_VIEW,
                        value: item.totalView_live
                    },
                    {
                        label: item.live.id + " - " + utils.cutLongString(item.live.name, 30),
                        type: VN.NUM_LIKE,
                        value: item.totalLike_live
                    },
                ])

            const recent100LiveStats = response.liveStats.recent100Livestream.shift()
            const prev100LiveStats = response.liveStats.previous100Livestream.shift()

            if (recent100LiveStats) {
                setDataView(recent100LiveStats.totalView)
                setDataLike(recent100LiveStats.totalLike)

                if (prev100LiveStats) {
                    setDataViewDiff((prev100LiveStats.totalView - recent100LiveStats.totalView) / prev100LiveStats.totalView * 100)
                    setDataLikeDiff((prev100LiveStats.totalLike - recent100LiveStats.totalLike) / prev100LiveStats.totalLike * 100)
                }
            }

            setData(_.flatten(dataResp))



        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [])

    if (loading) {
        return <LoadingSpin />
    }

    return (
        <>
            <div className="site-card-wrapper">
                <PageHeader
                    title={`Thống kê ${numLiveStats} livestream gần nhất`}
                    extra={[
                        <InputNumber size="large" min={10} max={100} defaultValue={numLiveStats} onChange={(value) => setNumLiveStats(value)} />,
                        <Button onClick={fetchData} key="2">{VN.CHANGE}</Button>,
                    ]}
                />
                <Row gutter={16}>
                    <Col span={8}>
                        <Card bordered={false}>
                            {dataView || VN.UNKNOWN}
                            <Statistic
                                title={VN.TOTAL_VIEW}
                                value={Math.abs(dataViewDiff) === 0 ? "--" : Math.abs(dataViewDiff)}
                                precision={2}
                                valueStyle={{ color: dataViewDiff < 0 ? '#3f8600' : "#e80435" }}
                                prefix={dataViewDiff < 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                                suffix="%"
                            />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false}>
                            {dataLike || VN.UNKNOWN}
                            <Statistic
                                title={VN.TOTAL_LIKE}
                                value={Math.abs(dataLikeDiff) === 0 ? "--" : Math.abs(dataLikeDiff)}
                                precision={2}
                                valueStyle={{ color: dataLikeDiff < 0 ? '#3f8600' : "#e80435" }}
                                prefix={dataLikeDiff < 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                                suffix="%"
                            />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card bordered={false}>
                            {dataComment || VN.UNKNOWN}
                            <Statistic
                                title={VN.TOTAL_COMMENT}
                                value={Math.abs(dataCommentDiff) === 0 ? "--" : Math.abs(dataCommentDiff)}
                                precision={2}
                                valueStyle={{ color: dataCommentDiff < 0 ? '#3f8600' : "#e80435" }}
                                prefix={dataCommentDiff < 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                                suffix="%"
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
            <PageHeader
                title={VN.MOST_VIEWED_LIVESTREAM}
            />
            <Bar {...config} data={data}
                label={{
                    style: {
                        innerWidth: 100,
                        outerWidth: 100,
                        opacity: 1,
                        fontSize: 16
                    },
                }} />
        </>
    );
}

