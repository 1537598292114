/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";
import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/logo_hocmai.png";
import {
  PlaySquareOutlined, BellOutlined, MenuOutlined, ProfileOutlined,
  ReadOutlined, TeamOutlined, UserOutlined, VideoCameraAddOutlined,
  SendOutlined, ToolOutlined, BarChartOutlined,
} from '@ant-design/icons'
import utils from "../../utils"
import VN from '../../vn'
function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const role = JSON.parse(window.localStorage.getItem("role"))
  if (!role) {
    alert(VN.SESSION_EXPIRED)
    setTimeout(() => {
      utils.removeUserSavedInfo()
    }, 2000)
    return null
  }
  const roleId = role.id
  if (roleId === undefined || roleId === null) {
    alert(VN.SESSION_EXPIRED)
    setTimeout(() => {
      utils.removeUserSavedInfo()
    }, 2000)
    return null
  }
  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>{VN.BANNER}</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {[0, 3].indexOf(roleId) > -1 && <Menu.Item className="menu-item-header" key="5">
          {VN.LIVESTREAM}
        </Menu.Item>}

        {[0, 3].indexOf(roleId) > -1 && <Menu.Item key="5">
          <NavLink to="/livestreamstats">
            <span
              className="icon"
              style={{
                background: page === "livestreamstats" ? color : "",
              }}
            >
              <BarChartOutlined />
            </span>
            <span className="label">{VN.LIVESTREAM_STATS}</span>
          </NavLink>
        </Menu.Item>}

        {[0, 3].indexOf(roleId) > -1 && <Menu.Item key="1">
          <NavLink to="/livestream">
            <span
              className="icon"
              style={{
                background: page === "livestream" ? color : "",
              }}
            >
              <PlaySquareOutlined />
            </span>
            <span className="label">{VN.LIVESTREAM}</span>
          </NavLink>
        </Menu.Item>}
        {[0, 3].indexOf(roleId) > -1 && <Menu.Item key="5">
          <NavLink to="/sourceLive">
            <span
              className="icon"
              style={{
                background: page === "sourceLive" ? color : "",
              }}
            >
              <VideoCameraAddOutlined />
            </span>
            <span className="label">{VN.SOURCE_LIVE}</span>
          </NavLink>
        </Menu.Item>}



        {[0].indexOf(roleId) > -1 && <Menu.Item className="menu-item-header">
          {VN.NOTIFICATION_CONFIG}
        </Menu.Item>}
        {[0].indexOf(roleId) > -1 && <Menu.Item key="7">
          <NavLink to="/notificationSend">
            <span
              className="icon"
              style={{
                background: page === "notification" ? color : "",
              }}
            >
              <SendOutlined />
            </span>
            <span className="label">{VN.SEND_PUSH_NOTIFICATION}</span>
          </NavLink>
        </Menu.Item>}
        {[0].indexOf(roleId) > -1 && <Menu.Item key="6">
          <NavLink to="/notificationType">
            <span
              className="icon"
              style={{
                background: page === "notification" ? color : "",
              }}
            >
              <ToolOutlined />
            </span>
            <span className="label">{VN.NOTIFICATION_TYPE}</span>
          </NavLink>
        </Menu.Item>}
        {[0].indexOf(roleId) > -1 && <Menu.Item key="7">
          <NavLink to="/notificationList">
            <span
              className="icon"
              style={{
                background: page === "notification" ? color : "",
              }}
            >
              <BellOutlined />
            </span>
            <span className="label">{VN.NOTIFICATION_LIST}</span>
          </NavLink>
        </Menu.Item>}
        {[0, 3].indexOf(roleId) > -1 && <Menu.Item className="menu-item-header" key="5">
          {VN.MORE_INFO}
        </Menu.Item>}
        {[0].indexOf(roleId) > -1 && <Menu.Item key="2">
          <NavLink to="/channel">
            <span
              className="icon"
              style={{
                background: page === "channel" ? color : "",
              }}
            >
              <ProfileOutlined />
            </span>
            <span className="label">{VN.CHANNEL}</span>
          </NavLink>
        </Menu.Item>}
        {[0, 3].indexOf(roleId) > -1 && <Menu.Item key="3">
          <NavLink to="/subject">
            <span
              className="icon"
              style={{
                background: page === "subject" ? color : "",
              }}
            >
              <ReadOutlined />
            </span>
            <span className="label">{VN.SUBJECT}</span>
          </NavLink>
        </Menu.Item>}
        {[0].indexOf(roleId) > -1 && <Menu.Item key="4">
          <NavLink to="/class">
            <span
              className="icon"
              style={{
                background: page === "class" ? color : "",
              }}
            >
              <TeamOutlined />
            </span>
            <span className="label">{VN.CLASS}</span>
          </NavLink>
        </Menu.Item>}
        {[0, 3].indexOf(roleId) > -1 && <Menu.Item key="6">
          <NavLink to="/teacher">
            <span
              className="icon"
              style={{
                background: page === "teacher" ? color : "",
              }}
            >
              <UserOutlined />
            </span>
            <span className="label">{VN.TEACHER}</span>
          </NavLink>
        </Menu.Item>}

      </Menu>
      {/* <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <span className="icon" style={{ color }}>
            {''}
          </span>
          <h6>Need Help?</h6>
          <p>Please check our docs</p>
          <Button type="primary" className="ant-btn-sm ant-btn-block">
            DOCUMENTATION
          </Button>
        </div>
      </div> */}
    </>
  );
}

export default Sidenav;
