
import {
    Button,
    Image,
    Table,
    Tag,
    Col,
    Row,
    Input,
} from "antd";
import dateformat from 'dateformat'
import { useContext, useEffect, useState } from "react";
import LivestreamModel from "../../models/Livestream";
import React from "react";
import constants from "../../constants";
import utils from "../../utils";
import Request from "../../api/Request";
import VN from "../../vn";
import LoadingSpin from "../../components/loadingSpin";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import ActionButtons from "../../components/actionButtons";


const columns = [
    {
        title: VN.STATUS,
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
            const text = utils.mapLivestreamStatus(status)
            switch (status) {
                case constants.LIVESTREAM_STATUS.COMING:
                    return <Tag color="blue">{text}</Tag>
                case constants.LIVESTREAM_STATUS.SHOWING:
                    return <Tag color="green">{text}</Tag>
                case constants.LIVESTREAM_STATUS.CLOSED:
                    return <Tag color="orange">{text}</Tag>
                case constants.LIVESTREAM_STATUS.INVISIBLE:
                    return <Tag color="grey">{text}</Tag>
                default:
                    return <Tag color="grey">{text}</Tag>
            }

        },
        filters: [
            {
                text: utils.mapLivestreamStatus(constants.LIVESTREAM_STATUS.COMING),
                value: constants.LIVESTREAM_STATUS.COMING,
            },
            {
                text: utils.mapLivestreamStatus(constants.LIVESTREAM_STATUS.SHOWING),
                value: constants.LIVESTREAM_STATUS.SHOWING,
            },
            {
                text: utils.mapLivestreamStatus(constants.LIVESTREAM_STATUS.CLOSED),
                value: constants.LIVESTREAM_STATUS.CLOSED,
            }, {
                text: utils.mapLivestreamStatus(constants.LIVESTREAM_STATUS.INVISIBLE),
                value: constants.LIVESTREAM_STATUS.INVISIBLE,
            },
        ],
        onFilter: (value, record) => record.status === value,
        filterSearch: true,
    },
    {
        title: VN.ID,
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: VN.NAME,
        dataIndex: 'name',
        key: 'name',
        render: (name) => utils.cutLongString(name),
    },
    {
        title: VN.DESCRIPTION,
        dataIndex: 'description',
        key: 'description',
        render: (description) => utils.cutLongString(utils.parseHTML(description))
    },
    {
        title: VN.CLASS,
        dataIndex: 'classId',
        key: 'classId',
        render: (classId) => utils.cutLongString(classId.map((item) => utils.mapClass(item)).join(", "), 20),
        filters: JSON.parse(localStorage.getItem('class')),
        onFilter: (value, record) => record.classId.indexOf(value) > -1,
        filterSearch: true,
    },
    {
        title: VN.SUBJECT,
        dataIndex: 'subjectId',
        // key: 'subjectId',
        render: (subjectId) => utils.cutLongString(subjectId.map((item) => utils.mapSubject(item)).join(", "), 20),
        filters: JSON.parse(localStorage.getItem('subject')),
        onFilter: (value, record) => record.subjectId.indexOf(value) > -1,
        filterSearch: true,

    },
    {
        title: VN.TEACHER,
        dataIndex: 'teacherId',
        // key: 'teacherId',
        render: (teacherId) => utils.cutLongString(teacherId.map((item) => utils.mapTeacher(item)).join(", "), 20),
        filters: JSON.parse(localStorage.getItem('teacher')),
        onFilter: (value, record) => record.teacherId.indexOf(value) > -1,
        filterSearch: true,
    },
    {
        title: VN.REQUIRE_LOGIN,
        dataIndex: 'requireLogin',
        key: 'requireLogin',
        render: (requireLogin) => requireLogin ? <Tag color="green">{VN.YES}</Tag> : <Tag color="red">{VN.NO}</Tag>,
        filters: [
            {
                text: VN.YES,
                value: true,
            },
            {
                text: VN.NO,
                value: false,
            },
        ],
        onFilter: (value, record) => record.requireLogin === value,
        filterSearch: true,
    },
    {
        title: VN.TEST,
        dataIndex: 'isTest',
        key: 'isTest',
        render: (isTest) => isTest ? <Tag color="green">{VN.YES}</Tag> : <Tag color="red">{VN.NO}</Tag>,
        filters: [
            {
                text: VN.YES,
                value: true,
            },
            {
                text: VN.NO,
                value: false,
            },
        ],
        onFilter: (value, record) => record.isTest === value,
        filterSearch: true,
    },
    {
        title: VN.MORE_INFO,
        dataIndex: 'detail',
        key: 'detail',
        render: (detail) => <Col>
            <Tag color="blue">{`${detail.totalView_live} view`}</Tag>
            <Tag color="pink">{`${detail.totalLike_live} like`}</Tag>
        </Col>

    },
    {
        title: VN.SMALL_COVER,
        dataIndex: 'smallCover',
        key: 'smallCover',
        render: (smallCover) => <Image src={smallCover}
            width={constants.IMAGE_DEFAULT_WIDTH}
            height={constants.IMAGE_DEFAULT_HEIGHT}
        />

    },
    {
        title: VN.MEDIUM_COVER,
        dataIndex: 'mediumCover',
        key: 'mediumCover',
        render: (mediumCover) => <Image src={mediumCover}
            width={constants.IMAGE_DEFAULT_WIDTH}
            height={constants.IMAGE_DEFAULT_HEIGHT}
        />
    },
    {
        title: VN.LARGE_COVER,
        dataIndex: 'largeCover',
        key: 'largeCover',
        render: (largeCover) => <Image src={largeCover}
            width={constants.IMAGE_DEFAULT_WIDTH}
            height={constants.IMAGE_DEFAULT_HEIGHT}
        />
    },
    {
        title: VN.START_TIME,
        dataIndex: 'startTime',
        key: 'startTime',
        render: (startTime) => dateformat(startTime, constants.DATE_FORMAT)
    },
    {
        title: VN.END_TIME,
        dataIndex: 'endTime',
        key: 'endTime',
        render: (endTime) => dateformat(endTime, constants.DATE_FORMAT)
    },
    {
        title: VN.ACTION,
        key: "actions",
        dataIndex: 'actions',
    },

];

export default function Livestream() {
    const [livestream, setLivestream] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [searchText, setSearchText] = useState("")
    const deleteHandler = async function (id) {
        try {
            const response = await Request.xhr(
                `${constants.API_DOMAIN}/live/delete`,
                false,
                { liveId: id },
                "POST"

            )
            if (response.status === 200) {
                await fetchData()
            }

        } catch (error) {
            console.log(error)
        }
    }
    const fetchData = async function () {
        setLoading(true)
        try {
            const response = await Request.xhr(`${constants.API_DOMAIN}/live?search=${searchText}`)
            let data = utils.mapDataModel(response.data, LivestreamModel)
            for (let item of data) {
                item.actions = <ActionButtons
                    onEdit={() => navigate('edit', {
                        state: {
                            id: item.id,
                            isEditing: true,
                        }
                    })}
                    onDelete={() => deleteHandler(item.id)}
                />
            }
            setLivestream(data)

        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [])

    if (loading) {
        return <LoadingSpin />
    }
    return (
        <>
            <Row>
                <Col span={18}>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => navigate('edit')}
                    >{VN.CREATE}</Button>
                </Col>
                <Col span={6}>
                    <Input.Search
                        value={searchText}
                        placeholder={VN.FIND_BY_NAME}
                        onChange={(e) => setSearchText(e.target.value)}
                        onSearch={fetchData}
                        enterButton />
                </Col>
            </Row>
            <Table
                dataSource={livestream}
                columns={columns} scroll={{ x: constants.TABLE_SCROLL_X, }} />
        </>
    );
}

