/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
  Routes,
  Route
} from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Livestream from "./pages/livestream/Livestream";
import Channel from "./pages/channel/Channel";
import Subject from "./pages/subject/Subject";
import Class from "./pages/class/Class";
import SourceLive from "./pages/sourceLive/SourceLive";
import Teacher from "./pages/teacher/Teacher";
import EditTeacher from "./pages/teacher/EditTeacher";
import EditSubject from "./pages/subject/EditSubject";
import EditChannel from "./pages/channel/EditChannel";
import EditClass from "./pages/class/EditClass";
import EditSourceLive from "./pages/sourceLive/EditSourceLive";
import EditLivestream from "./pages/livestream/EditLivestream";
import NotificationType from './pages/notificationType/NotificationType'
import NotificationList from './pages/notificationList/NotificationList'
import NotificationSend from './pages/notificationSend/NotificationSend'
import LivestreamStats from './pages/livestream/LivestreamStats'

import React, { useEffect } from "react";
import { getChannelData, getClassData, getSubjectData, getTeacherData } from "./services/actions";
import EditNotificationType from "./pages/notificationType/EditNotificationType";
import utils from "./utils";

function App() {
  const token = window.localStorage.getItem('token')

  const initData = async function () {
    if (!token) return
    try {
      await utils.getGlobalData()
    } catch (error) {
      console.log("INIT DATA IN APP.JS ERROR", error)
    }
  }

  useEffect(() => {
    initData()
  }, [])

  if (!token) {
    return (
      <Routes>
        <Route
          path="*"
          element={<SignIn />}
        />
      </Routes>
    )
  }
  return (
    <Routes>
      {/* <Route path="/sign-up" exact element={<SignUp />} /> */}
      <Route path="/sign-in" exact element={<SignIn />} />
      <Route path="/" element={<Main />}>
        <Route exact path="livestream" element={<Livestream />} />
        <Route exact path="livestream/edit" element={<EditLivestream />} />
        <Route exact path="livestreamstats" element={<LivestreamStats />} />

        <Route exact path="teacher" element={<Teacher />} />
        <Route exact path="teacher/edit" element={<EditTeacher />} />

        <Route exact path="sourceLive" element={<SourceLive />} />
        <Route exact path="sourceLive/edit" element={<EditSourceLive />} />

        <Route exact path="class" element={<Class />} />
        <Route exact path="class/edit" element={<EditClass />} />

        <Route exact path="subject" element={<Subject />} />
        <Route exact path="subject/edit" element={<EditSubject />} />

        <Route exact path="channel" element={<Channel />} />
        <Route exact path="channel/edit" element={<EditChannel />} />

        <Route exact path="notificationType" element={<NotificationType />} />
        <Route exact path="notificationType/edit" element={<EditNotificationType />} />
        <Route exact path="notificationList" element={<NotificationList />} />
        <Route exact path="notificationSend" element={<NotificationSend />} />
      </Route>
      <Route path="*" element={<Main />} />
    </Routes>
  );
}

export default App;
