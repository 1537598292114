import axios from 'axios';
import {
    Form,
    Radio,
    Button,
    Upload,
    Input,
    Alert,
    DatePicker,
    Tooltip,
    Collapse,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import VN from '../../vn'
import Request from '../../api/Request'
import constants from '../../constants';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Subject from '../../models/Subject';
import LoadingSpin from '../../components/loadingSpin';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-custom';
import { MultiSelect } from "react-multi-select-component";
import { getLocalStorage } from '../../services/actions'
const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};

export default function NotificationSend() {
    const endPoint = `${constants.API_DOMAIN}/HMsocket/notification/push`
    const [showAlert, setShowAlert] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [time, setTime] = useState()
    const [isSendNow, setIsSendNow] = useState(true)
    const [inAppContent, setInappContent] = useState('')
    const [isPush, setIsPush] = useState(false)
    const [form] = Form.useForm();
    const [numFilteredUser, setNumFilteredUser] = useState(0)
    const [countStatus, setCountStatus] = useState()
    const onChangeDate = function (date, dateString) {
        setTime(date)
    }
    const submitData = async function (formData) {
        setIsFetching(true)
        try {
            console.log({ formData })
            let reqData = {
                ...formData,
                classId: formData.classId.map((item) => item.value),
                inAppContent,
            }
            if (formData.image) {
                reqData.image = formData.image.file
            }
            reqData.listUserId = formData.userId

            if (isSendNow === false) {
                reqData.timeout = time - Date.now()
            }

            const resData = await Request.xhr(
                endPoint,
                false,
                reqData,
                'POST',
                true,
            )
            if (resData.status) {
                alert(`Thành công, thông báo sẽ được gửi đến ${resData.numUser || 0} người dùng`)
            }
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
        setIsFetching(false)
    }

    const countNumUserFiltered = async () => {
        const filter = form.getFieldsValue(['userId', 'idApp', 'classId'])
        filter.classId = filter.classId.map((item) => item.value)
        setCountStatus('validating')
        if (filter.userId) {
            filter.listUserId = filter.userId.split(",")
        }
        const reqData = {
            ...filter
        }
        try {
            const resData = await Request.xhr(
                `${constants.API_DOMAIN}/HMsocket/notification/check`,
                false,
                reqData,
                'POST',
            )
            setNumFilteredUser(resData.data.count)
            setCountStatus("success")

            // setIsFetching(false)
        } catch (error) {
            setCountStatus('error')
        }
    }

    return (
        <Form
            form={form}
            name="validate_other"
            {...formItemLayout}
            onFinish={submitData}
        >
            {showAlert && <Alert
                message={VN.SUCCESS}
                type="success"
            />}

            <Collapse
                activeKey={['0', '1', '2']}
            >

                <Collapse.Panel
                    header={VN.NOTIFICATION_CONFIG_INFO}
                >
                    <Form.Item
                        name="isTest"
                        label={VN.IS_TEST}
                        rules={[
                            {
                                required: true,
                                message: VN.PLEASE_ENTER_VALUE,
                            },
                        ]}
                        initialValue={false}
                    >
                        <Radio.Group
                            defaultValue={false}
                        // onChange={({ target }) => setIsPush(target.value)}
                        >
                            <Radio value={true}>{VN.YES}</Radio>
                            <Radio value={false}>{VN.NO}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={VN.NOTIFICATION_INAPP_TITLE}
                        name="inAppTitle"
                        rules={[
                            {
                                required: true,
                                message: VN.PLEASE_ENTER_VALUE,
                            },
                        ]}
                    >
                        <Input placeholder={VN.NOTIFICATION_INAPP_TITLE_PLACEHOLDER} />
                    </Form.Item>
                    <Form.Item
                        name="inAppDescription"
                        label={VN.NOTIFICATION_INAPP_DESC}
                        rules={[
                            {
                                required: true,
                                message: VN.PLEASE_ENTER_VALUE,
                            },
                        ]}
                    >
                        <Input placeholder={VN.NOTIFICATION_INAPP_DESC_PLACEHOLDER} />
                    </Form.Item>
                    <Form.Item
                        label={VN.NOTIFICATION_INAPP_CONTENT}
                        rules={[
                            {
                                required: true,
                                message: VN.PLEASE_ENTER_VALUE,
                            },
                        ]}
                        name="inAppContent"
                    >
                        <CKEditor
                            editor={ClassicEditor}
                            data={inAppContent}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setInappContent(data)
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={VN.IMAGE_IN_APP}
                        name="image"
                    >
                        <Upload
                            maxCount={1}
                            multiple={false}
                            beforeUpload={() => false}
                            listType="picture-card">
                            <Button icon={<UploadOutlined />}>{VN.UPLOAD_IMAGE}</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        name="isPush"
                        label={VN.IS_PUSH}
                        rules={[
                            {
                                required: true,
                                message: VN.PLEASE_ENTER_VALUE,
                            },
                        ]}
                        initialValue={isPush}
                    >
                        <Radio.Group
                            onChange={({ target }) => setIsPush(target.value)}
                        >
                            <Radio value={true}>{VN.YES}</Radio>
                            <Radio value={false}>{VN.NO}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={VN.POPUP_TITLE}
                        name="pushTitle"
                    >
                        <Input disabled={isPush === false} placeholder={VN.NOTIFICATION_TITLE_PLACEHOLDER} />
                    </Form.Item>
                    <Form.Item
                        name="pushBody"
                        label={VN.CONTENT}
                    >
                        <Input disabled={isPush === false} placeholder={VN.NOTIFICATION_BODY_PLACEHOLDER} />
                    </Form.Item>
                    <Form.Item
                        label={VN.NAME}
                        name="pushName"
                    >
                        <Input disabled={isPush === false} placeholder={VN.NOTIFICATION_NAME} />
                    </Form.Item>

                </Collapse.Panel>

                <Collapse.Panel
                    header={VN.NOTIFICATION_CONFIG_FILTER}
                >

                    <Tooltip
                        trigger={['focus']}
                        title={`${VN.USER_ID_INPUT_HINT} 610234,983214,890124`}
                    >
                        <Form.Item
                            label={VN.USER_ID}
                            name="userId"
                        >
                            <Input
                                onBlur={(value) => {
                                    countNumUserFiltered()
                                }}
                                placeholder="610234,983214,890124"
                            />
                        </Form.Item>
                    </Tooltip>


                    <Form.Item
                        name="idApp"
                        label={VN.PKGNAME}
                        rules={[
                            {
                                required: true,
                                message: VN.PLEASE_ENTER_VALUE,
                            },
                        ]}
                        initialValue={1}
                    >
                        <Radio.Group
                            onChange={(value) => {
                                countNumUserFiltered()
                            }}
                            defaultValue={1}
                        >
                            {/* id của app: 1: app HOCMAI | 2: app TIEUHOC */}
                            <Radio value={1}>{VN.HOCMAI}</Radio>
                            <Radio value={2}>{VN.HOCMAI_PRIMARY}</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="classId"
                        label={VN.CLASS}
                        initialValue={[]}
                    >
                        <MultiSelect
                            onChange={(value) => {
                                countNumUserFiltered()
                            }}
                            options={getLocalStorage('class').map((item) => ({ label: item.name, value: item.id }))}
                        />
                    </Form.Item>
                    <Form.Item
                        label={VN.ESTIMATE}
                        hasFeedback
                        validateStatus={countStatus}
                    >
                        <Input
                            disabled={true}
                            placeholder={`${VN.NOTIFICATION_FILTERED_COUNT}${numFilteredUser}`}
                        />
                    </Form.Item>
                </Collapse.Panel>

                <Collapse.Panel
                    header={VN.NOTIFICATION_CONFIG_FILTER}
                >

                    <Form.Item
                        label={VN.METHOD}
                        name="isSendNow"
                        initialValue={isSendNow}
                        rules={[
                            {
                                required: true,
                                message: VN.PLEASE_ENTER_VALUE,
                            },
                        ]}
                    >
                        <Radio.Group
                            value={isSendNow}
                            onChange={(data) => setIsSendNow(data.target.value)}>
                            <Radio value={true}>{VN.SEND_NOW}</Radio>
                            <Radio value={false}>{VN.TIME_OUT}</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        required
                        label={VN.SHOW_TIME}
                    >
                        <DatePicker
                            disabled={isSendNow === true}
                            placeholder={new Date()}
                            showTime={{ format: "YYYY-MM-DD HH:mm" }}
                            format="YYYY-MM-DD HH:mm"
                            onChange={onChangeDate}
                        />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 6,
                        }}
                    >
                        <Button
                            loading={isFetching}
                            // disabled={isFetching}
                            type="primary" htmlType="submit">
                            {VN.CONFIRM}
                        </Button>
                    </Form.Item>
                </Collapse.Panel>
            </Collapse>
        </Form>
    );
};
