import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";
import Channel from './Channel'
import Class from "./Class";
import Subject from './Subject'
export default class SourceLive {
    constructor(data = {}) {
        this.id = data.sourceLiveId || null
        this.name = data.name || ''
        this.url = data.url || ''
        this.length = data.length ? utils.secondsToHms(data.length) : ''
        this.channelRef = new Channel({ ...data.channelRef })
        this.classRef = new Class(data.classRef)
        this.subjectRef = new Subject(data.subjectRef)
        this.createdAt = data.createdAt ? dateFormat(new Date(data.createdAt), constants.DATE_FORMAT) : ''

        this.key = this.id
    }
}