import dateFormat, { masks } from "dateformat";
import constants from "../constants";
import utils from "../utils";
import Channel from "./Channel";
export default class NotificationType {
    constructor(data = {}) {
        this.title = data.title || []
        this.description = data.description || []
        this.pkgname = data.pkgname || []
        this.event = data.event || ""
        this.target = data.target || null
        this.content = data.content || ''
        this.pushPopupTitle = data.pushPopupTitle || ''
        this.pushPopupContent = data.pushPopupContent || ''
        this.image = data.image || null
        this.seen = data.seen || null
        this.id = data._id || data.notification_id || null
    }
}