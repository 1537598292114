const VN = {
    ID: "Mã số",
    NAME: "Tên",
    IMAGE: "Ảnh",
    DESCRIPTION: "Mô tả",
    ACTION: "Thao tác",
    CHANNEL: "Kênh",
    LINK: "Đường dẫn",
    LENGTH: "Độ dài",
    CREATED_AT: "Ngày tải lên",
    CLASS: "Lớp",
    SUBJECT: "Môn học",
    UPLOAD_IMAGE: "Tải ảnh lên",
    CONFIRM: "Xác nhận",
    SUCCESS: "Thành công",
    GENDER: "Giới tính",
    MALE: "Nam",
    FEMALE: "Nữ",
    OTHER: "Khác",
    PLEASE_ENTER_VALUE: "Nhập đầy đủ thông tin",
    CREATE: "Thêm mới",
    EDIT: "Xem/Sửa",
    DELETE: "Xóa",
    YES: "Có",
    NO: "Không",
    REQUIRE_LOGIN: "Yêu cầu đăng nhập",
    URL: "Đường dẫn",
    FIELD_ERROR: "Thông tin đã nhập không đúng",
    START_TIME: "Thời gian bắt đầu",
    END_TIME: "Thời gian kết thúc",
    TEST: "Thử nghiệm",
    CANCEL: "Hủy bỏ",
    ARE_YOU_SURE_TO_DELETE: "Bạn có chắc muốn xóa?",
    TEACHER: "Giáo viên",
    METHOD: "Hình thức",
    LIVE_NOW: "Phát trực tiếp",
    LIVE_LATER: "Hẹn giờ phát",
    SOURCE_LIVE: "Nguồn phát video",
    SMALL_COVER: "Ảnh bìa nhỏ",
    MEDIUM_COVER: "Ảnh bìa vừa",
    LARGE_COVER: "Ảnh bìa lớn",
    SHOW_TIME: "Thời gian phát",
    SIGN_IN: "Đăng nhập",
    ENTER_USERNAME_AND_PASSWORD: "Điền tên đăng nhập và mật khẩu",
    USERNAME: "Tên đăng nhập",
    PASSWORD: "Mật khẩu",
    REMEMBER_PASSWORD: "Nhớ mật khẩu",
    ERROR_HAPPENED: "Có lỗi xảy ra",
    BANNER: "HOCMAI Livestream",
    MORE_INFO: "Thông tin thêm",
    LIVESTREAM: "Livestream",
    SIGN_OUT: "Đăng xuất",
    COMING: "Chưa phát",
    SHOWING: "Đang phát",
    CLOSED: "Đã dừng",
    STATUS: "Trạng thái",
    NOT_ENOUGH_IMAGE: "Chưa chèn ảnh",
    INVISIBLE: "Không khả dụng",
    ERROR: "Xảy ra lỗi",
    HOCMAI_PRIMARY_ANDROID: "Học Mãi tiểu học - Android",
    HOCMAI_PRIMARY_IOS: "Học Mãi tiểu học - IOS",
    HOCMAI_ANDROID: "Học Mãi - Android",
    HOCMAI_IOS: "Học Mãi - IOS",
    PKGNAME: "Danh sách app",
    NOTIFICATION_CONFIG: "Thiết lập thông báo",
    NOTIFICATION: "Thông báo",
    EVENT: "Sự kiện",
    NOTIFICATION_TYPE: "Thiết lập thông báo",
    NOTIFICATION_LIST: "Danh sách",
    TARGET: "Đối tượng",
    INDIVIDUAL: "Cá nhân",
    GROUP: "Nhóm",
    ALL: "Toàn bộ",
    TITLE: "Tiêu đề",
    ADD: "Thêm",
    CONTENT: "Nội dung",
    SESSION_EXPIRED: "Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại",
    SEARCH_BY_ID: "Tìm theo ID người nhận",
    SEND_PUSH_NOTIFICATION: "Gửi thông báo",
    HOCMAI: "App HOCMAI",
    HOCMAI_PRIMARY: "App HOCMAI Tiểu Học",
    POPUP_TITLE: "Tiêu đề hiển thị",
    USER_ID: "ID người dùng",
    SEND_NOW: "Gửi luôn",
    TIME_OUT: "Hẹn giờ",
    PUSH_POPUP_TITLE: "Tiêu đề thông báo đẩy",
    PUSH_POPUP_CONTENT: "Nội dung thông báo đẩy",
    USER_ID_INPUT_HINT: "Nếu có nhiều user, mỗi ID cách nhau một dấu phảy. VD:",
    NOTIFICATION_TITLE: "Title của thông báo hiển thị trên điện thoại",
    NOTIFICATION_TITLE_PLACEHOLDER: "Title của thông báo đẩy",
    NOTIFICATION_BODY: "Nội dung của thông báo hiển thị trên điện thoại",
    NOTIFICATION_BODY_PLACEHOLDER: "Nội dung của thông báo đẩy",
    NOTIFICATION_NAME: "Tên của thông báo (dùng để phân loại, không gửi về người dùng)",
    NOTIFICATION_CONFIG_INFO: "Cấu hình thông báo",
    NOTIFICATION_CONFIG_FILTER: "Thêm bộ lọc",
    NOTIFICATION_INAPP_TITLE: "Tiêu đề (in-app)",
    NOTIFICATION_INAPP_TITLE_PLACEHOLDER: 'Tiêu đề hiển thị ở mục "Thông Báo" trong app',
    NOTIFICATION_INAPP_DESC: "Mô tả (in-app)",
    NOTIFICATION_INAPP_DESC_PLACEHOLDER: 'Mô tả hiển thị ở mục "Thông Báo" trong app',
    NOTIFICATION_INAPP_CONTENT: "Nội dung (in-app)",
    NOTIFICATION_INAPP_CONTENT_PLACEHOLDER: 'Nội dung hiển thị ở mục "Thông báo" trong app',
    IS_PUSH: "Gửi push notification",
    SEEN: "Đã xem",
    NOT_SEEN: "Chưa xem",
    IMAGE_IN_APP: "Ảnh (in-app)",
    NOTIFICATION_FILTERED_COUNT: "Số người nhận được thông báo: ",
    ESTIMATE: "Ước tính",
    LIVESTREAM_STATS: "Thống kê Livestream",
    MOST_VIEWED_LIVESTREAM: "Livestream có lượt xem cao nhất",
    LAST_MONTH_STATS: "Thống kê tháng này",
    LAST_20_VIDEO_STATS: "Thống kê 20 video gần nhất",
    TOTAL_VIEW: "Tổng lượt xem",
    TOTAL_LIKE: "Tổng lượt like",
    TOTAL_COMMENT: "Tổng lượt comment",
    UNKNOWN: "Chưa rõ",
    NUM_LIKE: "Số lượt thích",
    NUM_VIEW: "Số lượt view",
    CHANGE: "Thay đổi",
    FIND_BY_NAME: "Tìm theo tên",
    IS_TEST: "Thử nghiệm"
}
export default VN