/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
    Table,
    Image,
    Button,
} from "antd";
import { useEffect, useState } from "react";
import React from "react";
import constants from "../../constants";
import utils from "../../utils";
import ChannelModel from '../../models/Channel'
import Request from "../../api/Request";
import ActionButtons from "../../components/actionButtons";
import { PlusOutlined } from "@ant-design/icons";
import LoadingSpin from "../../components/loadingSpin";
import VN from "../../vn";
import { useNavigate } from "react-router";
const columns = [
    {
        title: VN.ID,
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: VN.NAME,
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: VN.DESCRIPTION,
        dataIndex: 'description',
        key: 'description',
        render: (description) => utils.cutLongString(utils.parseHTML(description))
    },
    {
        title: VN.IMAGE,
        dataIndex: 'image',
        key: 'image',
        render: image => {
            return (<Image
                height={constants.IMAGE_DEFAULT_HEIGHT}
                width={constants.IMAGE_DEFAULT_WIDTH}
                src={image}
            ></Image>)
        }
    },
    {
        title: VN.ACTION,
        key: "actions",
        dataIndex: 'actions'
    }
];

export default function Channel() {
    const [channel, setChannel] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const deleteChannel = async function (id) {
        try {
            const response = await Request.xhr(
                `${constants.API_DOMAIN}/channel/delete`,
                false,
                { channelId: id },
                "POST"

            )
            if (response.status === 200) {
                await fetchData()
            }

        } catch (error) {
            console.log(error)
        }
    }

    const fetchData = async function () {
        setLoading(true)
        try {
            const response = await Request.xhr(`${constants.API_DOMAIN}/channel`)
            let data = utils.mapDataModel(response.data, ChannelModel)
            for (let item of data) {
                item.actions = <ActionButtons
                    onEdit={() => navigate('edit', {
                        state: {
                            id: item.id,
                            isEditing: true,
                        }
                    })}
                    onDelete={() => deleteChannel(item.id)}
                />
            }
            setChannel(data)

        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }
    useEffect(() => {
        fetchData()
    }, [])

    if (loading) {
        return <LoadingSpin />
    }

    return (
        <>
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate('edit')}
            >{VN.CREATE}</Button>
            <Table
                dataSource={channel}
                columns={columns} scroll={{ x: constants.TABLE_SCROLL_X, }} />
        </>
    );
}

